const Layout = () => import('@/layout/index.vue')

export default {
  name: 'About',
  path: '/:language?/about',
  component: Layout,
  redirect: '/about/index',
  children: [
    {
      name: 'AboutIndex',
      path: 'index',
      component: () => import('@/views/about/index.vue'),
      meta: {
        title: 'About Future Intelligent',
        hideTab: false,
        menu: 'About',
        description: 'Future Intelligent is an innovative technology company in the consumer-goods sector.'
      }
    }
  ]
}
