document.documentElement.style.fontSize = '10px'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { useScreenStore } from '@/store/screen'
import App from './App.vue'
import router from './router'
import Btn from './components/Btn.vue'
import MobileBtn from './components/MobileBtn.vue'
import LightButton from './components/LightButton.vue'
import '@/style/editor.scss'
import './style/reset.css'

const pinia = createPinia()
pinia.use(piniaPersist)
const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(pinia)
app.component('Btn', Btn)
app.component('MobileBtn', MobileBtn)
app.component('LightButton', LightButton)

const screenStore = useScreenStore()
screenStore.SetScreenWidth(window.innerWidth)
window.addEventListener('resize', () => screenStore.SetScreenWidth(window.innerWidth))

window.addEventListener('scroll', () => {
	if (screenStore.menuShowMobile) screenStore.SetScreenMenuShowMobile(false)
})
app.mount('#app')
