const Layout = () => import('@/layout/index.vue')

export default {
  name: 'SalesStore',
  path: '/:language?/salesStore',
  component: Layout,
  redirect: '/salesStore/index',
  children: [
    {
      name: 'SalesStoreIndex',
      path: 'index',
      component: () => import('@/views/salesStore/index.vue'),
      meta: {
        title: 'Sales Store',
        hideTab: false,
        menu: 'SalesStore',
        description: 'Welcome to experience our products.'
      }
    }
  ]
}
