const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Product',
  path: '/:language?/product',
  component: Layout,
  redirect: '/product/index',
  children: [
    {
      name: 'ProductIndex',
      path: 'index',
      component: () => import('@/views/product/index.vue'),
      meta: {
        title: '产品列表 | 未来智能',
        hideTab: false,
        menu: 'Product',
        description: 'Product Guidelines and Troubleshooting.'
      }
    }
  ]
}
