const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Support',
  path: '/:language?/support',
  component: Layout,
  redirect: '/support/index',
  children: [
    {
      name: 'SupportIndex',
      path: 'index',
      component: () => import('@/views/support/index.vue'),
      meta: {
        title: 'Support | VIAIM',
        hideTab: false,
        menu: 'Support',
				description: "VIAIM Service and Support"
      }
    },
    {
      name: 'SupportProduct',
      path: 'product/:type',
      component: () => import('@/views/support/pages/nanoAir/index.vue'),
      meta: {
        title: '',
        hideTab: false,
        menu: 'Support',
        custom: true
      }
    },
    {
      name: 'ProductSeeMore',
      path: 'seeMore/:product/:id?',
      component: () => import('@/views/support/pages/seeMore/index.vue'),
      meta: {
        title: '',
        hideTab: false,
        menu: 'Support'
      }
    },
    {
      name: 'ProductDetail',
      path: 'detail/:id/:type?',
      component: () => import('@/views/support/pages/detail/mobileDetail.vue'),
      meta: {
        title: '',
        hideTab: false,
        menu: 'Support',
        back: true
      }
    },
    {
      name: 'VideoList',
      path: 'videoList/:product/:type?',
      component: () => import('@/views/support/pages/seeMore/pages/videoList.vue'),
      meta: {
        title: '',
        hideTab: false,
        menu: 'Support'
      }
    },
    {
      name: 'WarrantyPolicy',
      path: 'WarrantyPolicy',
      component: () => import('@/views/support/pages/WarrantyPolicy/Index.vue'),
      meta: {
        title: '',
        hideTab: false,
        menu: 'Support'
      }
    }
  ]
}
