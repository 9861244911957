export default {
  name: 'ExternalArticleTerms',
  path: '/:language?/external/articleTerms/:id?',
  component: () => import('@/views/external/articleTerms/index.vue'),
  meta: {
    title: 'Legal | VIAIM',
    hideTab: false,
    menu: '',
    description: "Before using VIAIM's services, please read carefully."
  }
}