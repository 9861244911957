<script setup lang='ts'>
import router from '@/router'
const props = defineProps<{
	text?: string
	type?: string
	path?: string
}>()

const onPage = () => {
	if (props.path) router.push(props.path)
}
</script>

<template>
	<div class='btn' :class="{ black: type === 'black', white: type === 'white' }" @click="onPage">
		<div>
			<span>{{ text }}</span>
			<svg class="iconpark-icon">
				<use href="#Property1Variant4"></use>
			</svg>
		</div>
	</div>
</template>

<style lang='scss' scoped>
.btn {
	display: inline-block;
	padding: 0 1rem 0 1.8rem;
	height: 4rem;
	gap: .4rem;
	border-radius: 2rem;
	user-select: none;
	border: 1px solid #ABABAB;

	div {
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.btn:active {
	background: rgba(0, 0, 0, 0.12);
}

.black {
	background: #000;
	color: #FFF;
	border: 1px solid #000;
}

.black:active {
	background: rgba(0, 0, 0, 0.8);
}

.white {
	background: #FFF;
	color: #000;
	border: 1px solid #ABABAB;
}
</style>
