const Layout = () => import('@/layout/index.vue')

export default {
	name: 'ArticleTerms',
	path: '/:language?/articleTerms',
	component: Layout,
	redirect: '/articleTerms/index',
	children: [
		{
			name: 'ArticleTermsIndex',
			path: 'index/:id',
			component: () => import('@/views/articleTerms/index.vue'),
			meta: {
				title: 'Legal | VIAIM',
				hideTab: false,
				menu: '',
				description: "Before using VIAIM's services, please read carefully."
			}
		}
	]
}