const Layout = () => import('@/layout/index.vue')

export default {
  name: 'RecDot',
  path: '/:language?/recdot',
  component: Layout,
  redirect: '/recdot/detail',
  children: [
    {
      name: 'recdotIndex',
      path: 'detail',
      component: () => import('@/views/recDot/index.vue'),
      meta: {
        title: 'viaim RecDot',
        hideTab: false,
        menu: 'RecDotDetail',
        description: '',
        image:''
      }
    }
  ]
}
