import { defineStore } from 'pinia'

export const useInternationalStore = defineStore('international', {
  state: () => ({
    location: { abb: 'en', code: null, region: 'Global', regionCn: '国际', icon: 'Global' },
    select: [
      { abb: 'en', code: null, region: 'Global', regionCn: '国际', icon: 'Global' },
      { abb: 'sg', code: 65, region: 'Singapore', regionCn: '新加坡', icon: 'Singapore' }
    ]
  }),
  actions: {
    SetInternationalLocation(code: any) {
      if (code === 65) {
        this.location = this.select[1]
      } else {
        this.location = this.select[0]
      }
    }
  }
})
