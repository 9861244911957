const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Download',
  path: '/:language?/download',
  component: Layout,
  redirect: '/download/index',
  children: [
    {
      name: 'DownloadIndex',
      path: 'index',
      component: () => import('@/views/download/index.vue'),
      meta: {
        title: 'Download | VIAIM',
        hideTab: false,
        menu: 'Download',
        description: 'Please download the software according to your product.'
      }
    }
  ]
}
