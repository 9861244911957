import { defineStore } from 'pinia'

export const useScreenStore = defineStore('screen', {
	state: () => ({
		screen: 'pc',
		windowWidth: 0,
		menuShowMobile: false,
		overflowScroll: false
	}),
	actions: {
		SetScreenWidth(data: any) {
			this.windowWidth = data
			this.screen = data > 768 ? 'pc' : 'mobile'
		},
		SetScreenMenuShowMobile(data: any) {
			this.menuShowMobile = data
		},
		SetScreenOverflowScroll(data: any) {
			this.overflowScroll = data
		}
	}
})
