import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    selected: ''
  }),
  actions: {
    SetMenuSelect(data: any) {
      this.selected = data
    }
  } 
})
 