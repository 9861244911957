<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
  content: String,
  leftIcon: Boolean,
  pointer: {
    type: Boolean,
    default: true
  }
})

const submitButton: any = ref(null)
const light: any = ref(null)
const show = ref(false)
// 鼠标移动
const mouseMove = (e: any) => {
  light.value.style.left = e.pageX - submitButton.value.offsetLeft + 'px'
  light.value.style.top = e.pageY - submitButton.value.offsetTop + 'px'
}
</script>

<template>
  <button class="LightButton" :class="{ pointer: props.pointer }" ref="submitButton" @mousemove="mouseMove" @mouseover="show = true" @mouseout="show = false">
    {{ props.content }}
    <svg class="iconpark-icon left-icon" v-show="props.leftIcon">
      <use href="#Property1Variant4"></use>
    </svg>
    <div v-show="show" class="light" ref="light"></div>
  </button>
</template>

<style lang="scss" scoped>
.LightButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 20px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: #1e1e1e;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  overflow: hidden;
  position: relative;

  .left-icon {
    margin-top: 1px;
    pointer-events: none; 
  }

  .light {
    position: absolute;
    width: 1px;
    height: 1px;
    box-shadow: 0px 0px 30px 15px rgba(255, 255, 255, 0.3);
    pointer-events: none; 
  }
}
.pointer {
  cursor: pointer;
}
</style>
